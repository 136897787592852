import { round } from './function.mjs'

// alarm type of msgType in influxDB
export const ALARM_MESSAGE_TYPE = 3;

// alarm limit types of sensors (SOURCE: data specification rev14)
const LIMIT_TYPE = {
  off: 0,
  absolute: 1, // "fixed limit"
  refFixed: 2, // "reference mixed limit" 
  relative: 3, // "reference relative limits"
}

const RESOLUTION = 500 // data points in aggregateWindow
const moreThanZero = (int) => {
  return int > 0 ? int : 1;
}
const calulateWindow = (hours) => {
  return moreThanZero(Math.round((hours * 3600)/RESOLUTION)) + "s"
}

const DEFAULT_REFERENCE_TIME = "-1d";
const timeRanges = (referenceTime = DEFAULT_REFERENCE_TIME, activationTime = DEFAULT_REFERENCE_TIME) => {
  return [
    { // i: 0
      name: 'three-hours',
      range: {
        start: '-3h',
        end: null
      },
      aggregateWindow: calulateWindow(3)
    },
    { // i: 1
      name: 'one-day',
      range: {
        start: '-1d',
        end: null
      },
      aggregateWindow: calulateWindow(24)
    },
    { // i: 2
      name: 'one-week',
      range: {
        start: '-7d',
        end: null
      },
      aggregateWindow: calulateWindow(7 * 24)
    },
    { // i: 3
      name: 'one-month',
      range: {
        start: '-1mo',
        end: null
      },
      aggregateWindow: calulateWindow(30 * 24)
    },
    { // i: 4
      name: 'six-months',
      range: {
        start: '-6mo',
        end: null
      },
      aggregateWindow: calulateWindow(180 * 24)
    },    
    { // i: 5
      name: 'one-year',
      range: {
        start: '-1y',
        end: null
      },
      aggregateWindow: calulateWindow(365 * 24)
    },
    { // i: 6
      name: 'from-reference-time',
      range: {
        // if reference time is older than one month, start 30 days ago
        start: new Date().getTime() - new Date(referenceTime).getTime() > 2629800000 ? "-30d" : referenceTime,
        end: null
      },
      aggregateWindow: 
        referenceTime === '-1d' 
          ? calulateWindow(24)
          : moreThanZero(Math.round(((new Date().getTime()/1000) - (new Date(referenceTime).getTime()/1000))/RESOLUTION)) + 's'
    },
    { // i: 7
      name: 'from-activation-time',
      range: {
        start: activationTime,
        end: null
      },
      aggregateWindow: 
        activationTime === '-1d' 
          ? calulateWindow(24)
          : moreThanZero(Math.round(((new Date().getTime()/1000) - (new Date(activationTime).getTime()/1000))/RESOLUTION)) + 's'
    },
  ];
}

// TODO aggregateWindowin olisi parempi mennä saadun datan mukaan, ei haun mukaan
const customTimeRange = (start="", end="") => {
  return {
    name: 'custom-timerange',
    range: {
      start,
      end
    },
    aggregateWindow: moreThanZero(Math.round(((new Date(end).getTime()/1000) - (new Date(start).getTime()/1000))/RESOLUTION)) + "s"
  }
}

const strokeColor = {
  temperature: "#F47174",
  pressure: "#9933CC",
  pressureAtmos: "#9933CC",
  humidity: "#6699FF",
  "SPL": "#CC99FF",
  "PIR": "#99CC66",
  "luminance": "#CCCC00",
  "CO2": "#CC9966",
  "VOC": "#999933"
};

// a helper function for conversion: return null,
// if argument (but last, i.e. number of decimals) is null
const keepNull = (f) => (...args) => {
  if(args.slice(0,-1).includes(null)){
    return null;
  }
  return f(...args);
}
const DECIMALS = 2;
const unit = {
  SI: {
    general: {
      symbol: "",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    minutes: {
      symbol: "min",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    temperature: {
      symbol: '°C',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    pressure: {
      symbol: 'bar',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    pressureAtmos: {
      symbol: 'Pa',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    humidity: {
      symbol: '%',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    PIR: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    SPL: {
      symbol: 'dB',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    luminance: {
      symbol: 'Lux',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    CO2: {
      symbol: 'ppm',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    VOC: {
      symbol: 'ppb',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    ADvalue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    leakSensorADValue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
  },
  SAE: {
    general: {
      symbol: "",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    minutes: {
      symbol: "min",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    temperature: {
      symbol: '°F',
      conv: keepNull((x, decimals = DECIMALS) => round((x * 1.8) + 32, decimals)),
      convBack: keepNull((x, decimals = DECIMALS) => round((x - 32) / 1.8, decimals))
    },
    pressure: {
      symbol: 'psi',
      conv: keepNull((x, decimals = DECIMALS) => round(x * 14.5038, decimals)),
      convBack: keepNull((x, decimals = DECIMALS) => round(x / 14.5038, decimals)),
    },
    pressureAtmos: {
      symbol: 'Pa',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    humidity: {
      symbol: '%',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    PIR: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    SPL: {
      symbol: 'dB',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    luminance: {
      symbol: 'Lux',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    CO2: {
      symbol: 'ppm',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    VOC: {
      symbol: 'ppb',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    ADvalue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    leakSensorADValue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
  },
};

const getInfluxDesc = (commTech) => {
  switch (commTech) {
    case "Sigfox":
      return {
        bucket:      "SigfoxData",
        idFieldName: "sensorId",
      }
    case "LTE-M":
    default:
      return {
        bucket:      "DeviceData",
        idFieldName: "Device.deviceUUID",
      }
  }
}

export {
  LIMIT_TYPE,
  strokeColor,
  timeRanges,
  customTimeRange,
  getInfluxDesc,
  unit,
}